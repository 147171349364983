@tailwind base;
@tailwind components;
@tailwind utilities;

html {

}

.layout-header {
  @apply w-full px-8 md:px-32 xl:px-56 bg-greenblack 
  h-auto py-2
  md:h-32 md:pt-8 md:pb-8 
  shadow-pretty
  flex flex-row flex-wrap justify-center md:justify-between content-center items-center md:items-end
}

.layout-footer {
  @apply p-4 mt-8 text-center
  bg-paper-gray
  flex flex-row justify-center items-center
  shadow-sm
}
